import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import SpotlightButton from '../SpotlightButton';
import Modal from '../Modal';
import narrativeBg from '../../img/sl2/sl2-dots-2.svg';
import narrativeSpotlight from '../../img/sl2/butterfly-kid.png';
import kidJumping from '../../img/sl2/kid-jumping.png';
import sl2BackgroundIcons from '../../img/sl2/sl2-background.svg';
import sl2Dots from '../../img/sl2/sl2-dots.svg';

import './style.css';
import { spotlight2Data, spotlight2Content } from '../../data';

export default function Spotlight2() {
	const childRef = React.useRef();

	const openModal = () => {
		if (childRef.current) {
			childRef.current.toggleModal();
		}
	};

	const modalFooter = () => {
		return (
			<div className="image-container">
				<img className="sl2-section-bg" src={narrativeBg} alt="girl standing in front of butterfly painting" />
				<img className="sl2-section-img" src={narrativeSpotlight} alt="blue background illustration" />
			</div>
		);
	};
	const ParallaxImage = () => (
		<Parallax className="parallax" y={['-30%', '30%']} tagOuter="div">
			<img className="parallax__img parallax__img--sl2" src={sl2BackgroundIcons} alt="" />
		</Parallax>
	);
	return (
		<>
			<div className="sl2">
				{/* <img className="sl2__background" src={sl2BackgroundIcons} alt="" /> */}
				{ParallaxImage()}
				<div className="main-layer">
					<div className="container">
						<div className="sl2__block common-block">
							<h1 className="sl2__block-title js-ScrollReveal headline headline--1">
								{spotlight2Content.title}
							</h1>
							{spotlight2Content.message.map((item, index) => {
								return (
									<p key={index.toString()} className="js-ScrollReveal paragraph-text">
										{item.paragraph}
									</p>
								);
							})}
							<div className="sl2__block-row">
								<img className="sl2__block-image" src={kidJumping} alt="Kid jumping off of rock" />
								<div className="sl2__block-bucket">
									<div className="sl2__block-bucket-text">
										<span>{spotlight2Content.bucketBold}</span> {spotlight2Content.bucket}
									</div>
								</div>
							</div>
							<div className="sl2__action">
								<div data-hash="health-care" className="jump-link" />
								<img src={sl2Dots} alt="blue dot illustration" className="sl2__dots" />
								<SpotlightButton
									action={() => openModal()}
									image={narrativeSpotlight}
									mainColor="#7DC7E7"
									textColor="#1179AB"
									overlineColor="#035983"
									overlineText={spotlight2Content.spotlightOverline}
									titleText={spotlight2Content.spotlightTitle}
									isLeft
									btnMaxWidth="755px"
									btnImageWidth="25%"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				ref={childRef}
				backgroundColor="#7DC7E7"
				subtitleColor="#035983"
				titleColor="#1179AB"
				spotlightText={spotlight2Data.spotlightTitle}
				titleText={spotlight2Data.title}
				modalFooter={modalFooter}
				height={900}
				goToLeft
				modalId="js-modal2"
			>
				<div className="modal__body">
					{spotlight2Data.message.map((item, index) => {
						return (
							<p key={index.toString()} className="paragraph-text">
								{item.paragraph}
							</p>
						);
					})}
				</div>
			</Modal>
		</>
	);
}
