import React from 'react';
import './style.css';
import { downloadsData } from '../../data';

const downloadIconSvg = (
	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
		<g id="Group_809" data-name="Group 809" transform="translate(-291 -868)">
			<g id="Group_194" data-name="Group 194" transform="translate(291 868)">
				<g id="Group_193" data-name="Group 193">
					<path
						id="Path_225"
						data-name="Path 225"
						d="M14,13.3c0,.2-.1.3-.2.5s-.3.2-.5.2H.7c-.2,0-.3-.1-.5-.2s-.2-.3-.2-.5V10.2c0-.2.1-.3.2-.5s.3-.2.5-.2h4L6,11a1.284,1.284,0,0,0,1,.4A1.284,1.284,0,0,0,8,11L9.3,9.7h4c.2,0,.3.1.5.2s.2.3.2.5ZM8.1,0c.2,0,.3.1.5.2s.2.3.2.5V5.3h2.4a.55.55,0,0,1,.5.3.471.471,0,0,1-.1.6L7.4,10.4a.5.5,0,0,1-.8,0L2.5,6.2a.471.471,0,0,1-.1-.6.55.55,0,0,1,.5-.3H5.3V.7c0-.2.1-.3.2-.5s.2-.2.4-.2Zm2.3,12.3a.568.568,0,1,0-.8.8.6.6,0,0,0,1-.4C10.6,12.5,10.6,12.4,10.4,12.3Zm1.8,0a.568.568,0,1,0-.8.8.568.568,0,1,0,.8-.8Z"
						fill="#fff"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default function Downloads() {
	const downloadLinksHandler = () =>
		downloadsData.map((item, index) => {
			return (
				<a key={index.toString()} href={item.url} target="_blank" rel="noopener noreferrer" title={item.title}>
					<img src={item.img} alt={item.title} />

					<span>
						{downloadIconSvg} {item.title}
					</span>
				</a>
			);
		});

	return (
		<div className="downloads">
			<div data-hash="downloads" className="jump-link" />
			<div className="main-layer">
				<div className="container">
					<div className="downloads__block">
						<h1>DOWNLOADS</h1>

						<div className="books-container">
							{downloadLinksHandler()}
							{/* 
							<a
								href="/downloads/2019-grantmaking.pdf"
								target="_blank"
								rel="noopener noreferrer"
								title="2020 Financial Addendum"
							>
								<img src={annualBookPoster} alt="2020 Financial Addendum" />
								<span>{downloadIconSvg} 2020 Financial Addendum</span>
							</a>

							<a
								href="/downloads/2019-grantmaking.pdf"
								target="_blank"
								rel="noopener noreferrer"
								title="2020 Grantmaking Addendum"
							>
								<img src={annualBookPoster} alt="2020 Grantmaking Addendum" />
								<span>{downloadIconSvg} 2020 Grantmaking Addendum</span>
							</a> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
