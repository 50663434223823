import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import './style.css';

function ScrollButtons({ isTopBtnVisible, scrollToView, goToSection, isNextBtnVisible, isPrevBtnVisible }) {
	const topBtnAnimation = useSpring({
		to: async (next) => {
			await next({ display: 'block' });
			await next({ top: isTopBtnVisible ? '-10px' : '50px', opacity: isTopBtnVisible ? '1' : '0' });
			await next({ display: isTopBtnVisible ? 'block' : 'none' });
		},
	});

	const nextBtnAnimation = useSpring({
		to: async (next) => {
			await next({ display: 'block' });
			await next({ bottom: isNextBtnVisible ? '60px' : '50px', opacity: isNextBtnVisible ? '1' : '0' });
			await next({ display: isNextBtnVisible ? 'block' : 'none' });
		},
	});

	const prevBtnAnimation = useSpring({
		to: async (next) => {
			await next({ display: 'block' });
			await next({ bottom: isPrevBtnVisible ? '10px' : '-0px', opacity: isPrevBtnVisible ? '1' : '0' });
			await next({ display: isPrevBtnVisible ? 'block' : 'none' });
		},
	});

	return (
		<div className="scroll_buttons_container">
			{/* Got to the top of the site */}
			<animated.div className="btnAnim" style={topBtnAnimation}>
				<button type="button" aria-label="scroll to top of page" onClick={() => scrollToView(0)}>
					<svg
						className="pagenav-svg pagenav-svg--top"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 42 36"
						width="42"
						height="36"
						fill="none"
					>
						<path d="M2,19 21,2 L40,19" />
						<path d="M2,31 21,14 L40,31" />
					</svg>
				</button>
			</animated.div>

			{/* Got to prev section */}
			<animated.div className="btnAnim" style={nextBtnAnimation}>
				<button
					className="prevBtn"
					aria-label="scroll to previous section"
					type="button"
					onClick={() => goToSection(-1)}
				>
					<svg
						className="pagenav-svg"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 42 24"
						width="42"
						height="24"
						fill="none"
					>
						<path d="M2,21 L21,2 L40,21" />
					</svg>
				</button>
			</animated.div>

			{/* Got to next section */}
			<animated.div className="btnAnim" style={prevBtnAnimation}>
				<button
					className="nextBtn"
					aria-label="scroll to next section"
					type="button"
					onClick={() => goToSection(1)}
				>
					<svg
						className="pagenav-svg"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 42 24"
						width="42"
						height="24"
						fill="none"
					>
						<path d="M2,2 L21,21 L40,2" />
					</svg>
				</button>
			</animated.div>
		</div>
	);
}

ScrollButtons.propTypes = {
	isTopBtnVisible: PropTypes.bool.isRequired,
	isNextBtnVisible: PropTypes.bool.isRequired,
	isPrevBtnVisible: PropTypes.bool.isRequired,
	scrollToView: PropTypes.func.isRequired,
	goToSection: PropTypes.func.isRequired,
};

export default memo(ScrollButtons);
