import React, { useState } from 'react';
import './style.css';

// eslint-disable-next-line import/no-mutable-exports
let menuToggleHandler = '';

export default function Menu() {
	const [toggle, setToggle] = useState(false);
	menuToggleHandler = () => {
		setToggle(!toggle);
	};

	function scrollToSection() {
		const { hash } = window.location;
		if (hash) {
			const target = document.querySelectorAll(`[data-hash=${hash.substring(1)}]`);
			if (target[0]) {
				// target[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
				const y = target[0].getBoundingClientRect().top + window.pageYOffset;
				window.scrollTo({ top: y, behavior: 'smooth' });
			}
			// close out of menu
			setToggle(false);
		}
	}

	function validateSameHash(event) {
		// we need this in case user has the same hash, but wants to jump scroll back then they can
		if (event.target.getAttribute('href') === window.location.hash) {
			scrollToSection();
		}
	}

	window.addEventListener('hashchange', scrollToSection, false);
	window.onload = () => {
		scrollToSection();
	};

	return (
		<div className="menu">
			<button
				className={`HamburgerMenuButton ${toggle ? 'HamburgerMenuButtonActive' : ''}`}
				type="button"
				onClick={menuToggleHandler}
				aria-label="navigation dropdown"
			>
				<span />
			</button>

			<div className={`Links ${toggle ? 'ActiveMenu' : ''}`}>
				<a href="#letter-from-our-leaders" onClick={validateSameHash}>
					letter from our leaders
				</a>
				<a href="#roots-for-growth" onClick={validateSameHash}>
					ROOTS FOR GROWTH
				</a>
				<a href="#child-and-family-well-being" onClick={validateSameHash}>
					CHILD & FAMILY WELL-BEING
				</a>
				<a href="#health-care" onClick={validateSameHash}>
					HEALTH CARE
				</a>
				<a href="#higher-education" onClick={validateSameHash}>
					HIGHER EDUCATION{' '}
				</a>
				<a href="#rural-church" onClick={validateSameHash}>
					RURAL CHURCH
				</a>
				<a href="#special-initiatives" onClick={validateSameHash}>
					SPECIAL INITIATIVES
				</a>
				<a href="#meet-our-leaders" onClick={validateSameHash}>
					MEET OUR LEADERS
				</a>
				<a href="#news" onClick={validateSameHash}>
					News
				</a>
				<a href="#downloads" onClick={validateSameHash}>
					Downloads
				</a>
			</div>
		</div>
	);
}

export { menuToggleHandler };
