import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import SpotlightButton from '../SpotlightButton';
import Modal from '../Modal';
import sl3Bg from '../../img/sl3/narrative-3-bg.svg';
import sl3Spotlight from '../../img/sl3/college-students.png';
import sl3BackgroundIcons from '../../img/sl3/sl3-background.svg';
// import sl3Flowers from '../../img/sl3/sl3-flowers.svg';
import bucketImage from '../../img/sl3/doctor.png';
import './style.css';
import { spotlight3Data, spotlight3Content } from '../../data';

export default function Spotlight3() {
	const childRef = React.useRef();

	const openModal = () => {
		if (childRef.current) {
			childRef.current.toggleModal();
		}
	};

	const modalFooter = () => {
		return (
			<div className="image-container">
				<img className="sl3-section-bg" src={sl3Bg} alt="red flowers with blue specs" />
				<img className="sl3-section-img" src={sl3Spotlight} alt="two students together on lawn" />
			</div>
		);
	};
	const ParallaxImage = () => (
		<Parallax className="parallax" y={['-30%', '30%']} tagOuter="div">
			<img
				className="parallax__img parallax__img--sl3"
				src={sl3BackgroundIcons}
				alt="red background illustration with red flowers"
			/>
		</Parallax>
	);
	return (
		<>
			<div className="sl3">
				{/* <img className="sl3__background" src={sl3BackgroundIcons} alt="" /> */}
				{ParallaxImage()}

				<div className="main-layer">
					<div className="container">
						<div className="sl3__block common-block">
							<h1 className="sl3__block-title js-ScrollReveal headline headline--1">
								{spotlight3Content.title}
							</h1>
							<div className="sl3__block-content">
								<div className="sl3__block-column sl3__block-column--left">
									<div className="sl3__block-message">
										<p className="js-ScrollReveal paragraph-text">
											While the pandemic spurred new emergency relief grants, its disparate impact
											also sparked reflection about how the Endowment’s four grantmaking areas
											approach considerations of racial equity, diversity and inclusion on an
											ongoing basis. Recognizing room for improvement, program areas pledged to
											sharpen their focus on equity issues.
										</p>
										<p className="js-ScrollReveal paragraph-text">
											In <strong>Health Care,</strong> COVID-19 highlighted inequities around
											access to care and basic social needs. It created momentum for policy
											changes well-aligned with Health Care’s long-standing goal of reducing
											health disparities. Staff supported health equity training for its partners
											in the AccessHealth initiative, a coalition of community-based care networks
											for people without insurance, and also for leaders of Healthy People,
											Healthy Carolinas, a health-promotion effort powered by local coalitions.
											Health Care intends to help partners develop best practices and shared
											standards to advance health equity in all communities.
										</p>
										<p className="js-ScrollReveal paragraph-text">
											<strong>Child & Family Well-Being</strong> pledged to use an equity lens to
											inform its child welfare work and to study the potential impact on
											communities of color before committing new or ongoing support for
											evidence-based or evidence informed programs.
										</p>
									</div>
								</div>
								<div className="sl3__block-column">
									<img className="sl3__block-bucket-image" src={bucketImage} alt="doctor" />
									<div className="sl3__block-bucket">
										{/* <img className="sl3__flowers" src={sl3Flowers} alt="" /> */}
										<svg
											className="sl3__flowers"
											xmlns="http://www.w3.org/2000/svg"
											width="75.635"
											height="215.134"
											viewBox="0 0 75.635 215.134"
										>
											<g
												id="Group_1139"
												data-name="Group 1139"
												transform="translate(12.93 -4.363) rotate(8)"
											>
												<g
													id="Group_953"
													data-name="Group 953"
													transform="translate(31.347 0) rotate(0deg)"
													className="sl3__flowers-icon-1"
												>
													<path
														id="Path_986"
														data-name="Path 986"
														d="M12.783,76.654c-.823-2.043-.005-1.787-1.155-17.657C9.756,33.063,3.215,12.768.041,1.227A.968.968,0,0,1,1.906.712C9.918,29.8,6.244,14.92,13.281,49L18.444,74.21c.242,1.587.472,2.625-.662,3.761A2.994,2.994,0,0,1,12.783,76.654Z"
														transform="translate(11.359 14.124)"
														fill="#a0ab32"
													/>
													<path
														id="Path_987"
														data-name="Path 987"
														d="M0,15.89c-.01-3.7,2.861-6.809,6.783-5.274C5.326,5.408,7.223,1.556,9.161.565c4.036-2.079,8.826,1.706,7.094,9.284,5.01.493,6.154,6.221,5.111,10.054,4.823.33,5.476,6.378,2.211,10.351,2.395,2.787,1.472,5.658.39,8.669,3.833-1.026,10.5,2.948,7.046,8.58-2.045,3.371-6.39,4.2-10.53,1.084-.967,6.453-10.345,8.678-12.1,2.22a8.491,8.491,0,0,1,5.3-9.8C6.1,42.818,2.611,29.8,9.642,28.772a7.07,7.07,0,0,1-1.01-5.955C5.549,22.635.012,20.17,0,15.89Zm16.238-2.8c-2.445-.18-2.07,5.917.455,6.565C17.737,19.331,19.77,13.789,16.238,13.09ZM25.7,46.86c1.94.148,2.991-1.733,3.22-2.778.114-2.015-1.744-3.093-3.117-3.336-1.972-.186-3.148.746-4.132,2.3C23.163,44.421,23.367,46.379,25.7,46.86ZM20.612,28.319c3.625-3.135,1.837-5.984-.479-4.409C17.794,25.919,19.277,28.389,20.612,28.319ZM11.406,49.5c-1.509,1.342.017,3,1.488,3.306a5.454,5.454,0,0,0,5.759-5.434C16.463,48.162,14.141,47.659,11.406,49.5Zm3.721-20.39a7.443,7.443,0,0,0-2.649-4.175A3.851,3.851,0,0,0,15.128,29.114Zm-5.718-9.8c1.407-.754-.479-4.613-3.984-4.474C3.876,15.191,6.97,20.123,9.41,19.314Z"
														transform="translate(0 0)"
														fill="#cd4329"
													/>
												</g>
												<g
													id="Group_954"
													data-name="Group 954"
													transform="translate(0 38.831) rotate(0deg)"
													className="sl3__flowers-icon-2"
												>
													<path
														id="Path_988"
														data-name="Path 988"
														d="M22.289,74.55c-1.076-1.921-.232-1.77-3.385-17.366C13.759,31.694,4.7,12.393.085,1.348A.968.968,0,0,1,1.87.6C13.506,28.442,7.976,14.146,19.276,47.059l8.317,24.349c.443,1.544.8,2.545-.179,3.816A2.993,2.993,0,0,1,22.289,74.55Z"
														transform="translate(11.214 13.916)"
														fill="#a0ab32"
													/>
													<path
														id="Path_989"
														data-name="Path 989"
														d="M.059,17.245c-.478-3.67,1.976-7.116,6.061-6.091C4.014,6.173,5.407,2.112,7.2.882c3.74-2.574,8.972.574,8.214,8.31,5.032-.148,6.893,5.391,6.345,9.326,4.826-.285,6.24,5.63,3.507,9.987,2.73,2.46,2.179,5.424,1.486,8.549,3.672-1.5,10.788,1.593,8.077,7.616-1.6,3.6-5.8,4.981-10.308,2.41-.139,6.525-9.159,9.92-11.717,3.738a8.493,8.493,0,0,1,4.015-10.4c-7.3,2.761-12.406-9.709-5.563-11.621A7.072,7.072,0,0,1,9.5,23.021C6.419,23.232.614,21.488.059,17.245Zm15.753-4.838c-2.448.132-1.3,6.133,1.283,6.457C18.09,18.409,19.4,12.654,15.812,12.407Zm13.671,32.3c1.943-.1,2.747-2.1,2.843-3.165-.144-2.014-2.124-2.847-3.517-2.914-1.979.065-3.027,1.137-3.805,2.807C26.653,42.609,27.105,44.524,29.483,44.706Zm-7.4-17.746c3.2-3.57,1.063-6.17-1.034-4.314C18.982,24.936,20.767,27.2,22.081,26.96Zm-6.445,22.18c-1.326,1.524.4,2.977,1.893,3.091a5.456,5.456,0,0,0,5.025-6.121C20.482,47.17,18.116,46.964,15.637,49.141Zm1.106-20.7a7.459,7.459,0,0,0-3.158-3.806A3.849,3.849,0,0,0,16.743,28.443Zm-6.915-9c1.3-.924-1.06-4.513-4.52-3.933C3.815,16.059,7.512,20.56,9.828,19.447Z"
														transform="translate(0 0)"
														fill="#cd4329"
													/>
												</g>
												<g
													id="Group_955"
													data-name="Group 955"
													transform="translate(29.689 123.241) rotate(0deg)"
													className="sl3__flowers-icon-3"
												>
													<path
														id="Path_990"
														data-name="Path 990"
														d="M.625,75.223c-.981-1.27-.62-2.271-.179-3.816L8.764,47.058C20.065,14.145,14.535,28.443,26.169.6a.968.968,0,0,1,1.785.747C23.341,12.393,14.28,31.7,9.136,57.183,5.984,72.78,6.827,72.629,5.752,74.549A2.994,2.994,0,0,1,.625,75.223Z"
														transform="translate(0 13.917)"
														fill="#a0ab32"
													/>
													<path
														id="Path_991"
														data-name="Path 991"
														d="M25.934,23.023A7.07,7.07,0,0,1,24.177,28.8c6.843,1.914,1.737,14.382-5.563,11.621a8.493,8.493,0,0,1,4.015,10.4C20.07,57,11.051,53.606,10.911,47.083,6.407,49.653,2.2,48.275.6,44.672c-2.709-6.025,4.405-9.12,8.078-7.616-.692-3.125-1.244-6.091,1.484-8.551-2.734-4.357-1.319-10.272,3.508-9.987-.55-3.934,1.311-9.472,6.343-9.326-.758-7.736,4.475-10.884,8.216-8.31,1.794,1.23,3.189,5.292,1.082,10.272,4.086-1.026,6.539,2.421,6.06,6.091C34.82,21.49,29.015,23.232,25.934,23.023Zm-7.6-4.159c2.589-.323,3.733-6.324,1.285-6.455C16.029,12.654,17.342,18.409,18.337,18.863ZM10.43,41.435c-.778-1.67-1.828-2.742-3.807-2.807-1.392.067-3.371.9-3.515,2.914.094,1.067.9,3.065,2.843,3.165C8.329,44.524,8.781,42.609,10.43,41.435Zm3.957-18.787c-2.1-1.857-4.233.742-1.036,4.312C14.667,27.2,16.452,24.937,14.387,22.648ZM12.878,46.112a5.452,5.452,0,0,0,5.026,6.12c1.495-.112,3.218-1.567,1.893-3.089C17.318,46.964,14.952,47.17,12.878,46.112Zm8.97-21.472a7.442,7.442,0,0,0-3.158,3.8A3.848,3.848,0,0,0,21.849,24.639Zm8.278-9.123c-3.462-.583-5.821,3.007-4.52,3.933C27.922,20.56,31.618,16.059,30.126,15.516Z"
														transform="translate(3.821 0)"
														fill="#cd4329"
													/>
												</g>
											</g>
										</svg>

										<p className="sl3__block-bucket-text">
											<span>{spotlight3Content.bucketBold}</span> {spotlight3Content.bucket}
										</p>
									</div>
								</div>
							</div>
							<div className="sl3__action">
								<div data-hash="higher-education" className="jump-link" />
								<SpotlightButton
									action={() => openModal()}
									image={sl3Spotlight}
									mainColor="#F39168"
									textColor="#D33A29"
									overlineColor="#A81F25"
									overlineText={spotlight3Content.spotlightOverline}
									titleText={spotlight3Content.spotlightTitle}
									isLeft={false}
									btnMaxWidth="755px"
									btnImageWidth="29%"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				ref={childRef}
				backgroundColor="#F39168"
				subtitleColor="#A81F25"
				titleColor="#D33A29"
				spotlightText={spotlight3Data.spotlightTitle}
				titleText={spotlight3Data.title}
				modalFooter={modalFooter}
				height={1100}
				goToLeft={false}
				modalId="js-modal3"
			>
				<div className="modal__body">
					{spotlight3Data.message.map((item, index) => {
						return (
							<p key={index.toString()} className="paragraph-text">
								{item.paragraph}
							</p>
						);
					})}
				</div>
				<div className="modal__bucket">
					<div className="modal__list">
						<h3 className="modal__list-title">{spotlight3Data.listTitle}</h3>
						<ul className="modal__list-block">
							<li className="modal__list-item paragraph-text">
								<strong>Davidson College’s</strong> Commission on Race and Slavery released a report and
								recommendation for improving understanding of the college’s history regarding slavery.
								It also guided development of curricular and community opportunities to recognize that
								aspect of the school’s past.
							</li>
							<li className="modal__list-item paragraph-text">
								<strong>Furman University</strong> implemented recommendations from its Task Force on
								Slavery and Justice, formed in 2017 to understand and acknowledge the university’s
								historical connections to slavery.
							</li>
							<li className="modal__list-item paragraph-text">
								<strong>Johnson C. Smith University</strong> partnered with UNC Charlotte and Queens
								University of Charlotte on a year-long project to address Charlotte’s history of racism
								and its impact at each school.
							</li>
						</ul>
					</div>
				</div>
			</Modal>
		</>
	);
}
