import React, { useEffect, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import SpotlightButton from '../SpotlightButton';
import Modal from '../Modal';
import narrativeSvg1 from '../../img/narrative/narrative-1.svg';
import narrativeSvg2 from '../../img/narrative/narrative-2.svg';
import narrativeBg from '../../img/narrative/narrative-bg.svg';
import narrativeTop from '../../img/narrative/narrative-top.svg';
import narrativeSpotlight from '../../img/narrative/roots-for-growth-woman.png';
import narrativeSpotlight2 from '../../img/narrative/holding-hands.png';
import narrativeBackgroundIcons from '../../img/narrative/narrative-background.svg';
import narrativeLeaf from '../../img/narrative/narrative-leaf.svg';
import petalsLeft from '../../img/narrative/narrative-petals-left.svg';
import bucketImage from '../../img/narrative/child-with-gramps.png';
import seed1 from '../../img/narrative/seeds/seed-1.svg';
import seed2 from '../../img/narrative/seeds/seed-2.svg';
import seed3 from '../../img/narrative/seeds/seed-3.svg';
import seed4 from '../../img/narrative/seeds/seed-4.svg';
import seed5 from '../../img/narrative/seeds/seed-5.svg';
import seed6 from '../../img/narrative/seeds/seed-6.svg';
import seed7 from '../../img/narrative/seeds/seed-7.svg';
import seed8 from '../../img/narrative/seeds/seed-8.svg';
import seed9 from '../../img/narrative/seeds/seed-9.svg';
import seed10 from '../../img/narrative/seeds/seed-10.svg';
import seed11 from '../../img/narrative/seeds/seed-11.svg';
import seed12 from '../../img/narrative/seeds/seed-12.svg';
import seed13 from '../../img/narrative/seeds/seed-13.svg';
import seed14 from '../../img/narrative/seeds/seed-14.svg';
import seed15 from '../../img/narrative/seeds/seed-15.svg';
import './style.css';
import { narrativeData, narrativeContent } from '../../data/index';

export default function Narrative() {
	// -------------
	// animation start
	// -------------
	const targetObserver = React.useRef();
	const [isVisible, setIsVisible] = useState(false);
	let observeLeafAnimation = null;

	const callbackFunction = (entries) => {
		const [entry] = entries;
		if (entry.isIntersecting) {
			observeLeafAnimation.unobserve(targetObserver.current);
			setIsVisible(entry.isIntersecting);
		}
	};

	const options = {
		rootMargin: '0px 0px 25% 0px',
		threshold: 1,
	};

	useEffect(() => {
		observeLeafAnimation = new IntersectionObserver(callbackFunction, options);
		if (targetObserver.current) {
			observeLeafAnimation.observe(targetObserver.current);
		}
	}, [targetObserver, options]);

	// -------------
	// animation end
	// -------------

	// -------------
	// Seeds Sprinkle start
	// -------------
	// start with our target element reference and our observer varible
	const seedTargetObserver = React.useRef();
	let observeSeedAnimation = null;

	// our function that will be called once the user hits the observed target element
	const callbackSeedFunction = (entries) => {
		const [entry] = entries;
		if (entry.isIntersecting) {
			observeSeedAnimation.unobserve(seedTargetObserver.current);
			const seeds = document.querySelectorAll('.narrative__petals-img');
			const seedsArr = Array.prototype.slice.call(seeds);
			seedsArr.forEach((element, index) => {
				setTimeout(() => {
					element.classList.add('is-active');
				}, index * 200);
			});
		}
	};
	// our options where the target will trigger at on the page
	const seedOptions = {
		threshold: 1,
	};

	useEffect(() => {
		// update our variable with the intersection observer
		observeSeedAnimation = new IntersectionObserver(callbackSeedFunction, seedOptions);
		if (seedTargetObserver.current) {
			observeSeedAnimation.observe(seedTargetObserver.current);
		}
	}, [seedTargetObserver, seedOptions]);

	// -------------
	// Seed Sprinkle end
	// -------------

	const childRef = React.useRef();

	const openModal = () => {
		if (childRef.current) {
			childRef.current.toggleModal();
		}
	};

	const modalFooter = () => {
		return (
			<div className="image-container">
				<img className="section-svg-1" src={narrativeSvg1} alt="" />
				<img className="section-svg-2" src={narrativeSvg2} alt="" />
				<img className="section-bg" src={narrativeBg} alt="" />
				<img className="section-img" src={narrativeSpotlight} alt="" />
				<img className="section-img-2" src={narrativeSpotlight2} alt="" />
			</div>
		);
	};

	const ParallaxImage = () => (
		<Parallax className="parallax" y={['-30%', '30%']} tagOuter="div">
			<img className="parallax__img parallax__img--narrative" src={narrativeBackgroundIcons} alt="" />
		</Parallax>
	);

	return (
		<>
			<div className="narrative">
				<div className="narrative__top" ref={targetObserver}>
					<img className="narrative__top-image" src={narrativeTop} alt="" />
				</div>
				{/* <img className="narrative__background" src={narrativeBackgroundIcons} alt="" /> */}
				{ParallaxImage()}
				<div className="main-layer">
					<div className="container">
						<div className="narrative__block common-block">
							<div className="jump-link" data-hash="roots-for-growth" />
							<img
								className={`narrative__block-leaf ${isVisible ? 'animate' : ''}`}
								src={narrativeLeaf}
								alt="floating leaf illustration"
							/>
							<h1 className="narrative__block-title js-ScrollReveal">{narrativeContent.title}</h1>
							<div className="narrative__block-content">
								<div className="narrative__block-column narrative__block-column--left">
									<div className="narrative__block-message">
										{narrativeContent.description.map((item, index) => {
											return index === 0 ? (
												<span key={index.toString()}>
													<p className="js-ScrollReveal paragraph-text">{item.paragraph}</p>
												</span>
											) : (
												<p key={index.toString()} className="js-ScrollReveal paragraph-text">
													{item.paragraph}
												</p>
											);
										})}
									</div>
								</div>
								<div className="narrative__block-column">
									<img
										className="narrative__block-bucket-image"
										src={bucketImage}
										alt="grandpa and grandson"
									/>
									<div className="narrative__block-bucket">
										<p className="narrative__block-bucket-text">
											<span>{narrativeContent.blockBold}</span> {narrativeContent.block}
										</p>
									</div>
								</div>
							</div>
							<div className="narrative__action">
								<div data-hash="child-and-family-well-being" className="jump-link" />
								<img
									className="narrative__petals-left"
									src={petalsLeft}
									alt="small petals around portrait"
								/>
								<div className="narrative__petals-block" ref={seedTargetObserver}>
									<img
										className="narrative__petals-img"
										src={seed1}
										alt="small petal illustration 1"
									/>
									<img
										className="narrative__petals-img"
										src={seed2}
										alt="small petal illustration 2"
									/>
									<img
										className="narrative__petals-img"
										src={seed3}
										alt="small petal illustration 3"
									/>
									<img
										className="narrative__petals-img"
										src={seed4}
										alt="small petal illustration 4"
									/>
									<img
										className="narrative__petals-img"
										src={seed5}
										alt="small petal illustration 5"
									/>
									<img
										className="narrative__petals-img"
										src={seed6}
										alt="small petal illustration 6"
									/>
									<img
										className="narrative__petals-img"
										src={seed7}
										alt="small petal illustration 7"
									/>
									<img
										className="narrative__petals-img"
										src={seed8}
										alt="small petal illustration 8"
									/>
									<img
										className="narrative__petals-img"
										src={seed9}
										alt="small petal illustration 9"
									/>
									<img
										className="narrative__petals-img"
										src={seed10}
										alt="small petal illustration 10"
									/>
									<img
										className="narrative__petals-img"
										src={seed11}
										alt="small petal illustration 11"
									/>
									<img
										className="narrative__petals-img"
										src={seed12}
										alt="small petal illustration 12"
									/>
									<img
										className="narrative__petals-img"
										src={seed13}
										alt="small petal illustration 13"
									/>
									<img
										className="narrative__petals-img"
										src={seed14}
										alt="small petal illustration 14"
									/>
									<img
										className="narrative__petals-img"
										src={seed15}
										alt="small petal illustration 15"
									/>
								</div>
								<SpotlightButton
									action={() => openModal()}
									image={narrativeSpotlight}
									mainColor="#F7D970"
									textColor="#DC8B26"
									overlineColor="#6A4311"
									overlineText={narrativeContent.spotlightOverline}
									titleText={narrativeContent.spotlightTitle}
									isLeft={false}
									btnMaxWidth="759px"
									btnImageWidth="29%"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				ref={childRef}
				backgroundColor="#F7D970"
				subtitleColor="#6A4311"
				titleColor="#DC8B26"
				spotlightText={narrativeData.spotlightTitle}
				titleText={narrativeData.title}
				modalFooter={modalFooter}
				goToLeft={false}
				modalId="js-modal1"
			>
				<div className="modal__body">
					{narrativeData.message.map((item, index) => {
						return (
							<p key={index.toString()} className="paragraph-text">
								{item.paragraph}
							</p>
						);
					})}
					<p className="paragraph-text paragraph-text--italic">Pictured: Virgie Anderson</p>
				</div>
			</Modal>
		</>
	);
}
