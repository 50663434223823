import React, { memo, useEffect, useState } from 'react';
import { menuSectionsKeys } from '../../data';
// import Context from '../../store/context';
import ScrollButtons from '../ScrollButtons';
import './style.css';

function ScrollToTop() {
	// const { globalState } = useContext(Context);

	const [isTopBtnVisible, setIsTopBtnVisible] = useState(false);
	const [isNextBtnVisible, setIsNextBtnVisible] = useState(false);
	const [isPrevBtnVisible, setIsPrevBtnVisible] = useState(false);

	// Show button when page is scrolled upto given distance
	const toggleVisibility = () => {
		if (window.pageYOffset > 300) {
			setIsTopBtnVisible(true);
		} else {
			setIsTopBtnVisible(false);
		}

		if (window.pageYOffset > 600) {
			setIsNextBtnVisible(true);
			setIsPrevBtnVisible(true);
		} else {
			setIsNextBtnVisible(false);
			setIsPrevBtnVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);
	}, []);

	const scrollToView = (key) => {
		if (key >= 0) {
			const section = menuSectionsKeys[key];
			window.location.hash = section;
			const target = document.querySelectorAll(`[data-hash=${section}]`);
			if (target[0]) {
				// target[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
				const y = target[0].getBoundingClientRect().top + window.pageYOffset;
				window.scrollTo({ top: y, behavior: 'smooth' });
			}
			// remove #hero if we get there
			if (key === 0) {
				const uri = window.location.toString();
				if (uri.indexOf('#') > 0) {
					const cleanUri = uri.substring(0, uri.indexOf('#'));
					window.history.replaceState({}, document.title, cleanUri);
				}
			}
		}
	};

	const goToSection = (direction) => {
		// create our variable for our founded section
		let foundSection = '';
		// grab all of our jump link sections
		const jumpLinks = document.getElementsByClassName('jump-link');
		// loop through each jump link to find what current section we are on
		[].forEach.call(jumpLinks, (el) => {
			const elPositions = el.getBoundingClientRect();
			// if we find a section that is not negative, that will be the first view we are on
			if (Math.ceil(elPositions.top) + 1 > 0 && foundSection === '') {
				// update our found section  variable so the next element wont go through this condition
				foundSection = el.getAttribute('data-hash');
				// find the section key we are on through out found section variable
				const foundSectionKey = menuSectionsKeys.indexOf(foundSection);
				// if direction is next and the new section key we are going towards is less than or equal to our key array length
				if (direction > 0 && foundSectionKey + direction <= menuSectionsKeys.length - 1) {
					// scroll to the next
					scrollToView(foundSectionKey + direction);
				} else if (direction < 0 && foundSectionKey + direction >= 0) {
					// if we are going to the prev section and our new key is greater or equal to 0
					// scroll to the prev
					scrollToView(foundSectionKey + direction);
				}
			}
		});
	};

	return (
		<div className="scroll-container">
			<ScrollButtons
				isTopBtnVisible={isTopBtnVisible}
				isNextBtnVisible={isNextBtnVisible}
				isPrevBtnVisible={isPrevBtnVisible}
				scrollToView={scrollToView}
				goToSection={goToSection}
			/>
		</div>
	);
}
export default memo(ScrollToTop);
