import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import NewsModal from '../NewsModal';
import image1 from '../../img/news/elbow-pump.png';
import image2 from '../../img/news/michele-manceaux.png';
import image3 from '../../img/news/baby.png';
import image4 from '../../img/news/upstream.png';
import image5 from '../../img/news/workshop.png';
import newsBackgroundIcons from '../../img/news/news-background.svg';
import './style.css';

import { newsModalData1, newsModalData2, newsModalData3, newsModalData4, newsModalData5 } from '../../data/index';
import { menuToggleHandler } from '../Menu';

export default function News() {
	const childRef = React.useRef();

	const openModal = (data) => {
		if (childRef.current) {
			childRef.current.toggleModal(data);
			if (document.querySelector('.HamburgerMenuButtonActive')) {
				menuToggleHandler();
			}
		}
	};

	const ParallaxImage = () => (
		<Parallax className="parallax" y={['-30%', '30%']} tagOuter="div">
			<img
				className="parallax__img parallax__img--news"
				src={newsBackgroundIcons}
				alt="sun shining illustration"
			/>
		</Parallax>
	);
	return (
		<>
			<div className="news">
				<div data-hash="news" className="jump-link" />
				{/* <img className="news__background" src={newsBackgroundIcons} alt="" /> */}
				{ParallaxImage()}
				<div className="main-layer">
					<div className="container">
						<div className="news__content">
							<h1 className="news__content-title">News</h1>
							<div className="news__content-list">
								<div className="news__content-item">
									<button
										className="news__content-btn"
										type="button"
										onClick={() => openModal(newsModalData1)}
									>
										<div className="news__content-btn-wrapper">
											<img className="img-bkg" src={image1} alt="two friends elbow pumping" />
										</div>
										<div className="news__content-label top-left">
											<div className="news__content-label-bkg">
												<svg className="news__content-svg">
													<clipPath id="news-clip-path-1" clipPathUnits="objectBoundingBox">
														<path d="M0.003,0.982 C0.005,0.971,-0.001,0.964,0.001,0.953 C0.005,0.936,0.007,0.917,0.009,0.896 C0.009,0.82,0.005,0.747,0.001,0.671 C-0.001,0.619,0.005,0.564,0.005,0.512 C0.007,0.353,-0.007,0.197,0.005,0.037 C0.005,0.019,0.024,0.009,0.039,0.006 C0.085,0.004,0.131,0.022,0.178,0.009 C0.185,0.009,0.191,0.008,0.198,0.007 C0.346,0.001,0.495,0.011,0.642,0.009 C0.729,0.009,0.817,-0.002,0.904,0.005 C0.911,0.004,0.917,0.002,0.923,0.001 C0.966,-0.005,1,0.013,1,0.079 C0.996,0.361,0.989,0.645,1,0.927 C1,1,0.914,0.987,0.865,0.993 C0.859,0.994,0.852,0.994,0.845,0.995 C0.813,0.998,0.78,1,0.748,1 C0.74,1,0.733,1,0.726,1 C0.631,0.995,0.537,0.989,0.442,0.982 L0.418,0.981 C0.363,0.968,0.31,0.989,0.257,0.993 C0.251,0.993,0.244,0.994,0.238,0.994 C0.185,0.995,0.132,1,0.08,0.995 C0.074,0.995,0.067,0.994,0.061,0.993 C0.043,0.99,0.024,0.987,0.008,0.983 L0.003,0.982" />
													</clipPath>
												</svg>

												<div
													className="news__content-clipped news__content-clipped--1"
													style={{ backgroundColor: '#08748c' }}
												/>
											</div>
											<p className="news__content-label-text"> COVID RELIEF GRANTS</p>
										</div>
									</button>
								</div>

								<div className="news__content-item">
									<button
										className="news__content-btn"
										type="button"
										onClick={() => openModal(newsModalData2)}
									>
										<div className="news__content-btn-wrapper">
											<img className="img-bkg" src={image2} alt="Michele Manceaux portrait" />
										</div>
										<div className="news__content-label bottom-left">
											<div className="news__content-label-bkg">
												<svg className="news__content-svg">
													<clipPath id="news-clip-path-2" clipPathUnits="objectBoundingBox">
														<path d="M0.976,0.983 C0.965,0.987,0.96,0.987,0.952,0.989 C0.949,0.99,0.946,0.991,0.943,0.991 C0.926,0.994,0.908,0.991,0.891,0.993 C0.885,0.993,0.879,0.993,0.873,0.994 C0.783,1,0.691,0.998,0.6,0.99 C0.593,0.99,0.586,0.989,0.579,0.989 C0.457,0.974,0.332,0.975,0.208,0.986 C0.201,0.987,0.194,0.987,0.186,0.988 C0.139,0.995,0.089,0.991,0.041,0.997 C-0.005,1,0.01,0.929,0.005,0.894 C0.005,0.887,0.005,0.881,0.005,0.874 C0.005,0.855,0.004,0.837,0.004,0.818 C0.004,0.811,0.004,0.805,0.005,0.799 C0.004,0.775,0.005,0.751,0.008,0.729 C0.008,0.723,0.009,0.717,0.01,0.712 C0.01,0.659,0.012,0.607,0.007,0.554 C0.007,0.549,0.006,0.544,0.006,0.54 C0.006,0.532,0.006,0.525,0.006,0.518 C0.006,0.511,0.006,0.503,0.007,0.496 C0.006,0.436,0.01,0.378,0.01,0.318 C0.009,0.311,0.009,0.305,0.008,0.298 C0.007,0.291,0.007,0.283,0.006,0.275 C0.006,0.268,0.006,0.26,0.007,0.252 C0.007,0.247,0.007,0.242,0.008,0.237 C0.008,0.226,0.01,0.217,0.008,0.207 C-0.001,0.149,0.004,0.096,0.008,0.04 C0.009,0.035,0.01,0.03,0.012,0.027 C0.02,0.015,0.031,0.009,0.045,0.007 C0.05,0.006,0.056,0.006,0.062,0.006 C0.121,0.019,0.179,0.009,0.239,0.003 C0.246,0.002,0.253,0.001,0.26,0.001 C0.302,-0.002,0.345,0.001,0.387,0.005 L0.406,0.006 C0.432,0.007,0.457,0.005,0.484,0.005 C0.55,0.004,0.617,0.008,0.684,0.011 L0.706,0.012 C0.755,0.013,0.806,0.007,0.854,0.004 C0.861,0.004,0.868,0.004,0.875,0.003 C0.917,0.007,0.996,0.001,0.998,0.042 C1,0.129,1,0.217,1,0.306 C0.991,0.48,0.985,0.654,0.996,0.827 C0.997,0.835,0.997,0.842,0.998,0.849 C0.996,0.894,1,0.941,0.979,0.979 C0.978,0.981,0.977,0.982,0.976,0.983" />
													</clipPath>
												</svg>
												<div
													className="news__content-clipped news__content-clipped--2"
													style={{ backgroundColor: '#85479C' }}
												/>
											</div>
											<p className="news__content-label-text">NEW FELLOWSHIP PROGRAM LAUNCHES</p>
										</div>
									</button>
								</div>
								<div className="news__content-item">
									<button
										className="news__content-btn"
										type="button"
										onClick={() => openModal(newsModalData3)}
									>
										<div className="news__content-btn-wrapper">
											<img className="img-bkg" src={image3} alt="baby" />
										</div>
										<div className="news__content-label top-left">
											<div className="news__content-label-bkg">
												<svg className="news__content-svg">
													<clipPath id="news-clip-path-3" clipPathUnits="objectBoundingBox">
														<path d="M0.985,1 C0.976,1,0.967,1,0.958,1 C0.919,1,0.883,1,0.843,1 C0.706,1,0.571,1,0.434,0.993 C0.333,0.987,0.236,0.991,0.137,1 C0.126,1,0.116,1,0.106,1 C0.076,1,0.049,0.998,0.028,0.985 C0.011,0.974,0.008,0.958,0.01,0.94 C0.011,0.933,0.012,0.927,0.014,0.922 C0.022,0.902,0.022,0.881,0.019,0.862 C0.003,0.753,0.005,0.646,0.018,0.539 C0.019,0.531,0.02,0.522,0.021,0.514 C0.025,0.466,0.028,0.42,0.025,0.371 C0.016,0.274,0.004,0.174,0.001,0.078 C0,0.071,0,0.063,0.001,0.057 C0.003,0.043,0.009,0.029,0.022,0.016 C0.029,0.012,0.035,0.01,0.042,0.011 C0.051,0.011,0.062,0.012,0.071,0.013 C0.126,0.015,0.18,0.016,0.234,0.017 C0.245,0.017,0.256,0.017,0.266,0.018 C0.342,0.018,0.419,0.03,0.495,0.03 C0.642,0.033,0.788,0.007,0.937,0.012 C0.946,0.012,0.954,0.013,0.962,0.013 C0.97,0.014,0.978,0.016,0.986,0.018 C0.994,0.02,0.997,0.026,0.998,0.034 C0.999,0.041,0.999,0.05,0.999,0.056 C0.998,0.062,0.997,0.068,0.995,0.074 C0.991,0.083,0.989,0.101,0.989,0.119 C0.989,0.223,0.995,0.322,0.974,0.426 C0.953,0.534,0.992,0.638,0.986,0.746 C0.986,0.773,0.98,0.803,0.98,0.83 C0.977,0.868,0.986,0.904,0.995,0.942 C1,0.957,1,0.972,0.995,0.988 C0.993,0.995,0.99,1,0.987,1 C0.986,1,0.985,1,0.985,1" />
													</clipPath>
												</svg>
												<div
													className="news__content-clipped news__content-clipped--3"
													style={{ backgroundColor: '#ED5B26' }}
												/>
											</div>
											<p className="news__content-label-text">
												Get Ready Guilford Initiative Update
											</p>
										</div>
									</button>
								</div>
								<div className="news__content-item">
									<button
										className="news__content-btn"
										type="button"
										onClick={() => openModal(newsModalData4)}
									>
										<div className="news__content-btn-wrapper">
											<img className="img-bkg" src={image4} alt="young couple" />
										</div>
										<div className="news__content-label bottom-right">
											<div className="news__content-label-bkg">
												<svg className="news__content-svg">
													<clipPath id="news-clip-path-4" clipPathUnits="objectBoundingBox">
														<path d="M0.973,0.983 C0.962,0.988,0.957,0.987,0.949,0.989 C0.946,0.99,0.943,0.991,0.94,0.992 C0.923,0.995,0.905,0.992,0.888,0.993 C0.882,0.993,0.876,0.994,0.87,0.994 C0.78,1,0.688,0.998,0.597,0.991 C0.59,0.99,0.583,0.989,0.576,0.989 C0.454,0.974,0.329,0.975,0.205,0.986 C0.198,0.987,0.191,0.988,0.183,0.988 C0.136,0.995,0.086,0.991,0.038,0.997 C-0.008,1,0.007,0.929,0.002,0.894 C0.002,0.887,0.002,0.881,0.002,0.875 C0.002,0.856,0.001,0.837,0.001,0.818 C0.001,0.811,0.001,0.805,0.002,0.799 C0.001,0.775,0.002,0.752,0.005,0.729 C0.005,0.723,0.006,0.718,0.007,0.712 C0.007,0.659,0.009,0.607,0.004,0.554 C0.004,0.549,0.003,0.545,0.003,0.54 C0.003,0.533,0.003,0.525,0.003,0.518 C0.003,0.511,0.003,0.504,0.003,0.496 C0.003,0.436,0.007,0.378,0.007,0.318 C0.006,0.312,0.006,0.305,0.005,0.298 C0.004,0.291,0.004,0.283,0.003,0.275 C0.003,0.268,0.003,0.26,0.004,0.252 C0.004,0.247,0.004,0.242,0.005,0.237 C0.005,0.226,0.007,0.217,0.005,0.207 C-0.004,0.149,0.001,0.096,0.005,0.04 C0.006,0.035,0.007,0.031,0.009,0.027 C0.017,0.015,0.028,0.009,0.042,0.007 C0.047,0.006,0.053,0.006,0.059,0.006 C0.118,0.019,0.176,0.009,0.236,0.003 C0.243,0.002,0.25,0.002,0.257,0.001 C0.299,-0.001,0.342,0.001,0.384,0.005 L0.403,0.006 C0.429,0.007,0.454,0.005,0.481,0.005 C0.547,0.004,0.614,0.008,0.681,0.011 L0.703,0.012 C0.752,0.013,0.802,0.007,0.851,0.004 C0.858,0.004,0.865,0.004,0.872,0.003 C0.914,0.007,0.993,0.001,0.995,0.042 C1,0.129,1,0.217,0.997,0.306 C0.988,0.48,0.982,0.654,0.993,0.828 C0.994,0.835,0.994,0.842,0.995,0.849 C0.993,0.894,0.997,0.941,0.976,0.98 C0.975,0.982,0.974,0.983,0.973,0.983" />
													</clipPath>
												</svg>
												<div
													className="news__content-clipped news__content-clipped--4"
													style={{ backgroundColor: '#4859A7' }}
												/>
											</div>
											<p className="news__content-label-text">UPSTREAM USA</p>
										</div>
									</button>
								</div>
								<div className="news__content-item">
									<button
										className="news__content-btn"
										type="button"
										onClick={() => openModal(newsModalData5)}
									>
										<div className="news__content-btn-wrapper">
											<img className="img-bkg" src={image5} alt="team working together" />
										</div>
										<div className="news__content-label top-right">
											<div className="news__content-label-bkg">
												<svg className="news__content-svg">
													<clipPath id="news-clip-path-5" clipPathUnits="objectBoundingBox">
														<path d="M0.021,0.981 C0.044,0.985,0.069,0.993,0.093,0.993 C0.1,0.993,0.107,0.993,0.114,0.993 C0.121,0.994,0.128,0.994,0.135,0.994 C0.143,0.995,0.151,0.996,0.159,0.996 C0.253,1,0.344,1,0.438,1 L0.466,1 C0.521,1,0.576,1,0.633,1 C0.643,1,0.653,1,0.663,1 L0.722,1 C0.732,1,0.741,1,0.751,1 C0.844,1,1,1,1,0.928 C1,0.923,0.999,0.917,0.998,0.913 C0.976,0.811,0.971,0.715,0.969,0.616 C0.97,0.608,0.97,0.6,0.97,0.591 C0.972,0.503,0.974,0.415,0.974,0.327 C0.974,0.24,0.99,0.149,0.97,0.063 C0.966,0.056,0.962,0.05,0.957,0.046 C0.932,0.019,0.886,0.005,0.822,0.011 C0.578,0.034,0.341,0.013,0.099,0.02 C0.089,0.021,0.081,0.021,0.072,0.022 C0.048,0.024,0.031,0.029,0.028,0.036 C-0.012,0.183,-0.003,0.32,0.015,0.47 C0.016,0.477,0.017,0.485,0.018,0.493 C0.028,0.576,0.022,0.657,0.011,0.739 C0.01,0.745,0.01,0.752,0.009,0.759 C0.005,0.831,0.029,0.902,0.02,0.974 C0.021,0.978,0.021,0.981,0.021,0.981" />
													</clipPath>
												</svg>
												<div
													className="news__content-clipped news__content-clipped--5"
													style={{ backgroundColor: '#E8A622' }}
												/>
											</div>
											<p className="news__content-label-text">Refining Our Strategy</p>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NewsModal ref={childRef} />
		</>
	);
}
