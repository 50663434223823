import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import SpotlightButton from '../SpotlightButton';
import Modal from '../Modal';
import narrativeBg from '../../img/sl5/narrative-5-bg.svg';
import narrativeSvg from '../../img/sl5/narrative-5.svg';
import narrativeSpotlight from '../../img/sl5/parent-with-kids.png';
import sl5BackgroundIcons from '../../img/sl5/sl5-background.svg';
import bucketImage from '../../img/sl5/parent-kids-swing.png';
import sl5Leafs from '../../img/sl5/sl5-leafs.svg';
import sl5Petals from '../../img/sl5/sl5-petals.svg';
import './style.css';
import { spotlight5Data, spotlight5Content } from '../../data';

export default function Spotlight5() {
	const childRef = React.useRef();

	const openModal = () => {
		if (childRef.current) {
			childRef.current.toggleModal();
		}
	};

	const modalFooter = () => {
		return (
			<div className="image-container">
				<img className="sl5-section-bg" src={narrativeBg} alt="modal bacikground illustration" />
				<img className="sl5-section-svg" src={narrativeSvg} alt="yellow seeds" />
				<img className="sl5-section-img" src={narrativeSpotlight} alt="mother with her two kids" />
			</div>
		);
	};
	const ParallaxImage = () => (
		<Parallax className="parallax" y={['-30%', '30%']} tagOuter="div">
			<img
				className="parallax__img parallax__img--sl5"
				src={sl5BackgroundIcons}
				alt="background illustration of red flowers, greenery, and blue dots"
			/>
		</Parallax>
	);
	return (
		<>
			<div className="sl5">
				{/* <img className="sl5__background" src={sl5BackgroundIcons} alt="" /> */}
				{ParallaxImage()}
				<div className="main-layer">
					<div className="container">
						<div className="sl5__block common-block">
							<h1 className="sl5__block-title js-ScrollReveal headline headline--1">
								{spotlight5Content.title}
							</h1>
							{spotlight5Content.firstMessage.map((item, index) => {
								return (
									<p key={index.toString()} className="js-ScrollReveal paragraph-text">
										{item.paragraph}
									</p>
								);
							})}

							<div className="sl5__action">
								<img src={sl5Leafs} alt="yellow leafs" className="sl5__leafs" />
								<img src={sl5Petals} alt="orange petals" className="sl5__petals" />
								<div data-hash="special-initiatives" className="jump-link" />
								<SpotlightButton
									action={() => openModal()}
									image={narrativeSpotlight}
									mainColor="#76D1C1"
									textColor="#097482"
									overlineColor="#155861"
									overlineText={spotlight5Content.spotlightOverline}
									titleText={spotlight5Content.spotlightTitle}
									isLeft={false}
									btnMaxWidth="870px"
									btnImageWidth="22%"
								/>
							</div>
						</div>
						<div className="sl5__block-bottom common-block">
							<div className="sl5__block-content">
								<div className="sl5__block-column sl5__block-column--left">
									<div className="sl5__block-bucket">
										<p className="sl5__block-bucket-text">
											<span>{spotlight5Content.bucketBold}</span> {spotlight5Content.bucket}
										</p>
									</div>
									<img
										className="sl5__block-bucket-image"
										src={bucketImage}
										alt="mother swinging her two children on playset"
									/>
								</div>
								<div className="sl5__block-column sl5__block-column--right">
									<div className="sl5__block-message">
										{spotlight5Content.secondMessage.map((item, index) => {
											return (
												<p key={index.toString()} className="js-ScrollReveal paragraph-text">
													{item.paragraph}
												</p>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				ref={childRef}
				backgroundColor="#BBEBE1"
				subtitleColor="#087482"
				titleColor="#3DAC9B"
				spotlightText={spotlight5Data.spotlightTitle}
				titleText={spotlight5Data.title}
				modalFooter={modalFooter}
				height={950}
				goToLeft={false}
				modalId="js-modal5"
			>
				<div className="modal__body">
					{spotlight5Data.message.map((item, index) => {
						return (
							<p key={index.toString()} className="paragraph-text">
								{item.paragraph}
							</p>
						);
					})}
					<p className="paragraph-text paragraph-text--italic">Pictured: Sanaa Sharrieff and family</p>
				</div>
			</Modal>
		</>
	);
}
