import React from 'react';
import './style.css';
import footerLogo from '../../img/footer-logo.svg';

export default function Footer() {
	return (
		<div className="footer">
			<div className="main-layer">
				<div className="container">
					<div className="footer-container">
						<div className="jump-link" data-hash="footer" />
						<img className="footer-logo" src={footerLogo} alt="Duke 2020" />

						<h6>Copyright 2021 The Duke Endowment</h6>

						<div className="links-container">
							<a
								className="a2a_dd"
								href="https://www.addtoany.com/share#url=http%3A%2F%2Fannualreport.dukeendowment.org%2F2019&title=2019%20Duke%20Endowment%20Annual%20Report"
								title="SHARE"
							>
								SHARE
							</a>
							<a href="https://www.dukeendowment.org/Subscribe" title="SUBSCRIBE">
								SUBSCRIBE
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
