import React, { useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import logo from '../../img/hero/logo.svg';
import theDukeEmpowermentImg from '../../img/hero/the-duke-empowerment.svg';
import chairMember from '../../img/hero/minor-shaw.png';
import president from '../../img/hero/rhett-mabry.png';
import heroBackgroundIcons from '../../img/hero/lfol-background.svg';
import seed1 from '../../img/hero/seeds/seed-1.svg';
import seed2 from '../../img/hero/seeds/seed-2.svg';
import seed3 from '../../img/hero/seeds/seed-3.svg';
import seed4 from '../../img/hero/seeds/seed-4.svg';
import seed5 from '../../img/hero/seeds/seed-5.svg';
import seed6 from '../../img/hero/seeds/seed-6.svg';
import seed7 from '../../img/hero/seeds/seed-7.svg';
import seed8 from '../../img/hero/seeds/seed-8.svg';
import seed9 from '../../img/hero/seeds/seed-9.svg';
import tulip1 from '../../img/hero/tulips/tulip-1.svg';
import tulip2 from '../../img/hero/tulips/tulip-2.svg';
import tulip3 from '../../img/hero/tulips/tulip-3.svg';
import tulip4 from '../../img/hero/tulips/tulip-4.svg';
import tulip5 from '../../img/hero/tulips/tulip-5.svg';
import './style.css';
import { heroData, lfolData } from '../../data/index';

export default function LetterFromOurLeaders() {
	// -----------
	// Seeds Animation Start
	// -----------

	// start with our target element reference and our observer varible
	const seedRightTargetObserver = React.useRef();
	let observeSeedRightAnimation = null;

	// our function that will be called once the user hits the observed target element
	const callbackSeedRightFunction = (entries) => {
		const [entry] = entries;
		if (entry.isIntersecting) {
			observeSeedRightAnimation.unobserve(seedRightTargetObserver.current);
			const rightSeeds = document.querySelectorAll('.lfol__seeds--green-right > .lfol__seeds-img');
			const rightSeedsArr = Array.prototype.slice.call(rightSeeds);
			rightSeedsArr.forEach((element, index) => {
				setTimeout(() => {
					element.classList.add('is-active');
				}, index * 200);
			});
		}
	};
	// our options where the target will trigger at on the page
	const seedRightOptions = {
		threshold: 1,
	};

	useEffect(() => {
		// update our variable with the intersection observer
		observeSeedRightAnimation = new IntersectionObserver(callbackSeedRightFunction, seedRightOptions);
		if (seedRightTargetObserver.current) {
			observeSeedRightAnimation.observe(seedRightTargetObserver.current);
		}
	}, [seedRightTargetObserver, seedRightOptions]);

	// -----------
	// Seeds Right
	// -----------

	// start with our target element reference and our observer varible
	const seedLeftTargetObserver = React.useRef();
	let observeSeedLeftAnimation = null;

	// our function that will be called once the user hits the observed target element
	const callbackSeedLeftFunction = (entries) => {
		const [entry] = entries;
		if (entry.isIntersecting) {
			observeSeedLeftAnimation.unobserve(seedLeftTargetObserver.current);
			const leftSeeds = document.querySelectorAll('.lfol__seeds--green-left > .lfol__seeds-img');
			const leftSeedsArr = Array.prototype.slice.call(leftSeeds);
			leftSeedsArr.forEach((element, index) => {
				setTimeout(() => {
					element.classList.add('is-active');
				}, index * 200);
			});
		}
	};
	// our options where the target will trigger at on the page
	const seedLeftOptions = {
		threshold: 1,
	};

	useEffect(() => {
		// update our variable with the intersection observer
		observeSeedLeftAnimation = new IntersectionObserver(callbackSeedLeftFunction, seedLeftOptions);
		if (seedLeftTargetObserver.current) {
			observeSeedLeftAnimation.observe(seedLeftTargetObserver.current);
		}
	}, [seedLeftTargetObserver, seedLeftOptions]);

	// -----------
	// Seeds Animation End
	// -----------

	const ParallaxImage = () => (
		<Parallax className="parallax" y={['-30%', '30%']} tagOuter="div">
			<img className="parallax__img parallax__img--lfol" src={heroBackgroundIcons} alt="" />
		</Parallax>
	);

	return (
		<div className="lfol">
			{/* <img className="lfol__background" src={heroBackgroundIcons} alt="" /> */}
			{ParallaxImage()}
			<div className="main-layer">
				<div className="container">
					<div className="lfol__hero">
						<div className="jump-link" data-hash="hero" />
						<h1 className="lfol__hero-image">
							<img className="lfol__hero-image-file" src={logo} alt="Roots for Growth" />
						</h1>
						<h2 className="lfol__hero-subtitle">{heroData.subtitle}</h2>
						<div className="lfol__hero-signature">
							<img
								className="lfol__hero-signature-file"
								src={theDukeEmpowermentImg}
								alt="The Duke Endowment"
							/>
						</div>
						<p className="lfol__hero-description">{heroData.description}</p>
					</div>
					<div className="lfol__block ">
						<div ref={seedLeftTargetObserver} className="lfol__seeds lfol__seeds--green-left">
							<img className="lfol__seeds-img" src={seed7} alt="seeds illustration 1" />
							<img className="lfol__seeds-img" src={seed8} alt="seeds illustration 2" />
							<img className="lfol__seeds-img" src={seed9} alt="seeds illustration 3" />
						</div>
						<div ref={seedRightTargetObserver} className="lfol__seeds lfol__seeds--green-right">
							<img className="lfol__seeds-img" src={seed1} alt="seeds illustration 4" />
							<img className="lfol__seeds-img" src={seed2} alt="seeds illustration 5" />
							<img className="lfol__seeds-img" src={seed3} alt="seeds illustration 6" />
							<img className="lfol__seeds-img" src={seed4} alt="seeds illustration 7" />
							<img className="lfol__seeds-img" src={seed5} alt="seeds illustration 8" />
							<img className="lfol__seeds-img" src={seed6} alt="seeds illustration 9" />
						</div>
						<div className="lfol__seeds lfol__seeds--red-right">
							<img className="lfol__tulips-img" src={tulip1} alt="red flower illustration 1" />
							<img className="lfol__tulips-img" src={tulip2} alt="red flower illustration 2" />
							<img className="lfol__tulips-img" src={tulip3} alt="red flower illustration 3" />
							<img className="lfol__tulips-img" src={tulip4} alt="red flower illustration 4" />
							<img className="lfol__tulips-img" src={tulip5} alt="red flower illustration 5" />
						</div>
						<div data-hash="letter-from-our-leaders" className="jump-link" />
						<h1 className="lfol__block-title js-ScrollReveal">{lfolData.title}</h1>
						{lfolData.message.map((item, index) => {
							return (
								<p key={index.toString()} className="js-ScrollReveal paragraph-text">
									{item.paragraph}
								</p>
							);
						})}
						<h2 className="lfol__block-title js-ScrollReveal">
							<span>{lfolData.signoffPart1}</span>
							{lfolData.signoffPart2}
						</h2>
						<div className="lfol__block-leaders">
							<div className="lfol__block-leaders-item">
								<img
									className="lfol__block-leaders-image"
									src={chairMember}
									alt="portrait of minor m. shaw, board chair"
								/>
								<p className="lfol__block-leaders-name">{lfolData.boardChair}</p>
							</div>
							<div className="lfol__block-leaders-item">
								<img
									className="lfol__block-leaders-image"
									src={president}
									alt="portrait of rhett n. mabry, president"
								/>
								<p className="lfol__block-leaders-name">{lfolData.president}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
