import React, { useEffect } from 'react';
import './theme.css';
import ScrollReveal from 'scrollreveal';
import TagManager from 'react-gtm-module';
import Menu from './components/Menu';
import LetterFromOurLeaders from './components/LetterFromOurLeaders';
import Narrative from './components/Narrative';
import Spotlight2 from './components/Spotlight2';
import Spotlight3 from './components/Spotlight3';
import Spotlight4 from './components/Spotlight4';
import Spotlight5 from './components/Spotlight5';
import News from './components/News';
import Leadership from './components/Leadership';
import Downloads from './components/Downloads';
import treeSvg from './img/tree.svg';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { googleAnalyticsUrl, googleTagManager } from './data';

function App() {
	const googleScriptHandler = () => {
		// Gtag URL
		const script = document.createElement('script');
		script.async = true;
		script.src = googleAnalyticsUrl;
		document.head.appendChild(script);
		// Gtag Func
		const scriptFunc = document.createElement('script');
		const scriptText = document.createTextNode(
			'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "UA-146565798-4");'
		);
		scriptFunc.appendChild(scriptText);
		document.head.appendChild(scriptFunc);
	};

	const googleTagScriptHandler = () => {
		const scriptFunc = document.createElement('script');
		const scriptText = document.createTextNode(googleTagManager);
		scriptFunc.appendChild(scriptText);
		document.head.appendChild(scriptFunc);
	};

	const googleTagManagerHandler = () => {
		const tagManagerArgs = {
			gtmId: 'GTM-5RL2QWJ',
		};

		TagManager.initialize(tagManagerArgs);
	};

	useEffect(() => {
		setTimeout(() => {
			const revealOptions = {
				distance: '150px',
				origin: 'bottom',
				opacity: 0,
				easing: 'ease-in',
				duration: 800,
				delay: 200,
				viewFactor: 0.4,
			};
			ScrollReveal().reveal(document.querySelectorAll('.js-ScrollReveal'), revealOptions);
		}, 1000);

		// Only run google scripts on production
		if (process.env.REACT_APP_ENVIRONMENT === 'production') {
			googleScriptHandler();
			googleTagScriptHandler();
			googleTagManagerHandler();
		}
	}, []);

	return (
		<div className="App">
			<div className="container container--fixed-tree">
				<img className="TreeSvg" alt="Site name" src={treeSvg} />
			</div>
			<Menu />
			<LetterFromOurLeaders />
			<Narrative />
			<Spotlight2 />
			<Spotlight3 />
			<Spotlight4 />
			<Spotlight5 />
			<Leadership />
			<News />
			<Downloads />
			<Footer />
			<ScrollToTop />
		</div>
	);
}

export default App;
