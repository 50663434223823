import minor from '../img/trustees/minor.png';
import dennis from '../img/trustees/dennis.png';
import jean from '../img/trustees/jean.png';
import empty from '../img/trustees/empty.png';
import william from '../img/trustees/william.png';
import john from '../img/trustees/john.png';
import ravenel from '../img/trustees/ravenel.png';
import pamela from '../img/trustees/pamela.png';
import harris from '../img/trustees/harris.png';
import constance from '../img/trustees/constance.png';
import trent from '../img/trustees/trent.png';
import thomas from '../img/trustees/thomas.png';
import charles from '../img/trustees/charles.png';
import clarence from '../img/trustees/clarence.png';
import kenneth from '../img/trustees/kenneth.png';
import judy from '../img/trustees/judy.png';
import annualCover from '../img/covers/TDE-AR-2020-COVER.png';
import financialCover from '../img/covers/TDE-FINANCIALS-2020-COVER.png';
import grantMakingCover from '../img/covers/TDE-GRANTMAKING-2020-COVER.png';
import newsImage1 from '../img/news/elbow-pump.png';
import newsImage2 from '../img/news/michele-manceaux.png';
import newsImage3 from '../img/news/baby.png';
import newsImage4 from '../img/news/upstream.png';
import newsImage5 from '../img/news/workshop.png';
import annualReport from './downloads/Duke_Endowmenet_2020_Annual-Report.pdf';
import financialAddendum from './downloads/Duke_Endowmenet_2020_Annual-Report_Financial_Addendum.pdf';
import grantmakingAddendum from './downloads/Duke_Endowmenet_2020_Annual-Report_Grantmaking_Addendum.pdf';

const headerData = {
	title: 'Roots for growth',
};

const heroData = {
	subtitle: 'our work in an unprecedented year',
	description: '2020 Annual Report',
};

const lfolData = {
	title: 'Letter from our Leaders',
	message: [
		{
			paragraph:
				'In 2020, we were reminded how fragile our health, communities and hopes for the future can be. Amid social distancing and social unrest, we learned how difficult life can feel when we cannot come together physically or philosophically.',
		},
		{
			paragraph:
				'At The Duke Endowment, the COVID-19 pandemic complicated our grantees’ work by pushing the most vulnerable among us — often people of color — further behind. Their families faced higher rates of sickness, death, financial hardship and housing instability. Black, Latino and Native American children fell further behind in school.',
		},
		{
			paragraph: 'Calls for racial equity and social justice never felt more urgent.',
		},
		{
			paragraph:
				'We began taking a deeper look at our grantmaking and organizational culture, hoping to better understand how we might take action to fight the pernicious effects of historic inequities and systemic bias across our work.',
		},
		{
			paragraph:
				'While we have a long history of working across diverse communities, we know we have room for improvement. That is why we chose “Roots for Growth” as the theme of this year’s annual report.',
		},
		{
			paragraph:
				'In his Indenture of Trust, the 1924 legal document that governs our grantmaking, James B. Duke planted the seeds of racial progress within the Endowment’s work. In an era when racist policies barred minorities from society’s benefits and protections, he made explicit provisions for his philanthropy to help Black students, orphans and hospital patients. While his aid could not change the fundamental injustices of a segregated society, it did seek to mitigate its effects.',
		},
		{
			paragraph:
				'Today, much work remains before we can safely say the color of a person’s skin no longer foreshadows their potential life outcomes.',
		},
		{
			paragraph:
				'In this Annual Report, you’ll learn how we are systematically reviewing our work through a racial equity, diversity and inclusion lens. We disaggregated data on our giving, looking for disparities. We directed special COVID-related funding to organizations serving communities of color and made major racial equity and diversity grants to colleges and universities we support. We provided three days of training for staff members to advance racial equity. And we joined hands with other foundations to invest in leaders of color across the Carolinas. ',
		},

		{
			paragraph:
				'The Indenture does not specifically position us as a racial justice funder. Nevertheless, it makes clear Mr. Duke’s intent to help the poor, regardless of their race.  With race and poverty so often and so tightly linked, our work on issues such as health care and child well-being leads us to confront racial disparities and systemic bias. As we strive for deeper impact, we know we need to be even more intentional about the role we can play. ',
		},
		{
			paragraph:
				'The quest for racial equity, diversity and inclusion remains as one of the most important challenges of our time. We do not pretend to have all the answers, but we stand committed to continuous learning and improvement. These efforts represent a starting line for us, not the finish.',
		},
	],
	signoffPart1: 'WE LOOK FORWARD TO ',
	signoffPart2: 'LEARNING AND GROWING WITH YOU.',
	boardChair: 'MINOR M. SHAW, BOARD CHAIR',
	president: 'RHETT N. MABRY, PRESIDENT',
};

const narrativeData = {
	spotlightTitle: 'Child & Family Well-Being:',
	title: 'Keeping Kin Together',
	message: [
		{
			paragraph:
				'When addiction nearly triggered foster care placement for her son’s five children, Virgie Anderson stepped in to claim them.',
		},
		{
			paragraph:
				'She never figured on parenting five school-aged grandkids at 74, but she could not countenance strangers raising them. The decision placed the Charleston resident in a little-noticed but increasingly important segment of the child welfare landscape: family members who take in underage relatives to prevent foster care placement. ',
		},
		{
			paragraph:
				'In South Carolina, about 69,000 children — mostly African American — live with kinship caregivers. Social services officials increasingly turn to these willing caregivers to keep siblings and families together. But additional support is crucial, since these are often older women battling health and poverty challenges themselves.',
		},
		{
			paragraph:
				'Anderson turned to HALOS, a Charleston-based organization that assists more than 300 kinship care families annually. It provides support groups, help for meeting basic needs and referrals to other community resources. During the pandemic, referrals shot up by more than 200 percent as these already challenged families faced additional burdens, said HALOS Executive Director Kim Clifton.',
		},
		{
			paragraph:
				'The assistance proved critical for Anderson, who struggled financially and faced heart disease and a bout with COVID-19 while overseeing her grandchildren.',
		},
		{
			paragraph: '“I was just so glad to be a part of HALOS,” she said. “DSS can only do so much.”',
		},
		{
			paragraph:
				'The Endowment supports HALOS with a $420,000 grant aimed at helping it adopt the more intensive “Success Coach” case management model for families needing more than just material or parental support. A special COVID-19 Emergency Financial Assistance Project created by HALOS also received Endowment support.',
		},
		{
			paragraph:
				'“Outcomes for children in kinship care tend to be better than for those placed in foster care,” said Ravenel Curry, chair of the Endowment’s Committee on Child & Family Well-Being. “We are proud to help HALOS as it works to keep children and families together.”',
		},
	],
};

const narrativeContent = {
	title: 'Roots for Growth',
	description: [
		{
			paragraph:
				'As James B. Duke drew up plans for the Endowment nearly a century ago, he realized how challenging it would be to meet his philanthropic goal of improving education, health, child welfare and spirituality in the Carolinas.',
		},
		{
			paragraph:
				'“This is a harder job than I thought it would be,” he said. “I’m beginning to think it is almost as difficult for a man to give away his money rightly as it is to make it.”',
		},
		{
			paragraph:
				'Generations later, using Mr. Duke’s charitable dollars most effectively remains a goal of our work. During COVID-19, that challenge grew even more complex as the pandemic took its disturbing and disproportionate toll on our grantees’ most vulnerable clients — often people of color.',
		},
		{
			paragraph:
				'The crisis underscored the fact that supporting racial equity, diversity and inclusion isn’t optional to the success of our work in North Carolina and South Carolina — it is essential.',
		},
	],
	blockBold: '“OUTCOMES FOR CHILDREN IN KINSHIP CARE TEND TO BE BETTER THAN FOR',
	block: 'THOSE PLACED IN FOSTER CARE.”',
	spotlightOverline: 'Child & Family Well-Being:',
	spotlightTitle: 'Keeping Kin Together',
};

const spotlight2Data = {
	spotlightTitle: 'Health Care:',
	title: 'Supporting Latino Communities',
	leftBoldText:
		'320 words or less Health Care — El Futuro agency bringing badly needed mental health services to underserved Latino population during the pandemic',
	message: [
		{
			paragraph:
				'As it widened racial disparities in health outcomes, the COVID-19 pandemic revealed how critically important it is for health care providers to expand their reach and build  trust with communities of color.',
		},
		{
			paragraph:
				'One organization doing so effectively is Durham-based El Futuro, a nonprofit mental health services provider focused on underserved Latino communities. Although the common stigma of therapy as a sign of “weakness” runs strong in some Latino communities, El Futuro draws nearly 1,800 clients per year for individual, group and family treatments. ',
		},
		{
			paragraph:
				'Key to El Futuro’s appeal is a humble, inviting approach centered around the theme of “La Mesita” — Spanish for “the little table.” Dr. Luke Smith, El Futuro’s executive director, says he and his staff see themselves not as teaching or leading clients, but rather learning with them, and with other service providers.',
		},
		{
			paragraph:
				'“We say pull up a chair to La Mesita and learn with us,” said Smith, a psychiatrist. “Everybody has a seat at the table.”',
		},
		{
			paragraph:
				'El Futuro focuses on building “confianza,” or trust, with clients. A therapeutic garden next to its building invites the community in, as does a colorful wall mural, Latino heritage celebrations and related workshops.',
		},
		{
			paragraph:
				'Its staff of nearly 50 employees hail from across Central and South America, but Smith says they also must show “calor humana,” or human warmth. For instance, no walking through the waiting room without greeting clients.',
		},
		{
			paragraph:
				'With a $675,000 grant from the Endowment, El Futuro also offers culturally appropriate training to more than 1,000 mental health professionals, school counselors, community outreach workers and others through its La Mesita Latinx Mental Health Network.',
		},
		{
			paragraph:
				'El Futuro’s trust building opens pathways to healing for clients who often have left behind rich social networks in their native countries. At times, they may struggle with loneliness, anxiety, depression and substance abuse.',
		},
		{
			paragraph:
				'“I felt like my life was not worth living,” said one client. “But not now, thank God. Everything is good, and thanks to the señoritas (therapists) who helped us, now I am very optimistic.”',
		},
		{
			paragraph:
				'“The pandemic showed how important it is to build trusting bonds between health care providers and the communities they serve,” said Charlie Lucas, chair of the Endowment’s Committee on Health Care. “El Futuro’s successes should serve as an encouraging example of how to bridge gaps and deliver critical services.”',
		},
	],
};

const spotlight2Content = {
	title: 'Centering Equity in Pandemic Response',
	message: [
		{
			paragraph:
				'The Endowment’s guiding principles require us to follow Mr. Duke’s intent to address current and emerging needs, provide ethical leadership by seeking diverse opinions, direct resources where they will produce the best results, and build effective relationships with grantees by listening to them, learning about their communities and challenges, and working together toward solutions.',
		},
		{
			paragraph:
				'The events of 2020 provided a rich opportunity for the Endowment to lean into these principles with an emphasis on learning more about under-resourced communities and working collaboratively to identify solutions. ',
		},
		{
			paragraph:
				'The Board of Trustees increased the Endowment’s 2020 giving by $35 million, with the new funds earmarked to assist increased demands for aid and communities disproportionately affected by the pandemic. Aided by an ad hoc staff committee, the effort to center equity in the Endowment’s COVID-19 grantmaking revolved around four principles: targeting resources to populations most impacted; optimizing interventions to those populations; funding organizations most proximate to those communities; and minimizing the application and reporting burden on grantees.',
		},
		{
			paragraph:
				'The work helped the Endowment forge new relationships with grassroots organizations. It also allowed program areas to open new avenues to engage communities of color.',
		},
	],
	bucketBold: '“THE PANDEMIC SHOWED HOW IMPORTANT IT IS TO BUILD TRUSTING BONDS BETWEEN',
	bucket: 'HEALTH CARE PROVIDERS AND THE COMMUNITIES THEY SERVE.”',
	spotlightOverline: 'Health Care:',
	spotlightTitle: 'SUPPORTING LATINO COMMUNITIES',
};

const spotlight3Data = {
	spotlightTitle: 'Higher Education:',
	title: 'Advancing Diversity and Inclusion',
	listTitle:
		'Initiatives addressing systemic racial inequity also moved forward at other Endowment-supported institutions of higher learning:',
	message: [
		{
			paragraph:
				'As protests erupted nationwide over the death of George Floyd, Duke University President Vincent Price penned an open letter denouncing racial bias and laying out an aggressive new anti-racism campaign for the school. He called for significant increases in student, faculty and staff diversity, and announced plans to embed anti-racism instruction in curricula and programs across the university. He backed the plan with measurable goals and timetables aimed at weaving anti-racism and equity into the long-term priorities of Duke.',
		},
		{
			paragraph:
				'“Ultimately,” he wrote, “real progress will require an embrace of both personal and institutional humility, admitting to our blindness, our lack of understanding, and confusion.”',
		},

		{
			paragraph: 'The Endowment supported the university’s plan with a five-year, $16 million grant.',
		},
		{
			paragraph:
				'Among other things, it will underwrite anti-racism trainings and initiatives to connect Duke to local communities of color.',
		},
		{
			paragraph:
				'“This is a defining moment for higher education as institutions commit to an intentional approach to diversity and inclusion,” said Bill Barnet, chair of the Endowment’s Committee on Higher Education. “We are proud to support Duke University as it seeks to lead the way on this timely and critically important issue.”',
		},
	],
};

const spotlight3Content = {
	title: 'Program Areas Take Stock',
	bucketBold:
		'“ULTIMATELY, REAL PROGRESS WILL REQUIRE AN EMBRACE OF BOTH PERSONAL AND INSTITUTIONAL HUMILITY, ADMITTING',
	bucket: 'TO OUR BLINDNESS, OUR LACK OF UNDERSTANDING, AND CONFUSION.”',
	spotlightOverline: 'Higher Education:',
	spotlightTitle: 'ADVANCING DIVERSITY AND INCLUSION',
};

const spotlight4Data = {
	spotlightTitle: 'Rural Church:',
	title: 'Helping Pastors Heal Divisions',
	message: [
		{
			paragraph:
				'When the Rev. Tammy Ingram found herself leading two congregations — one Black and one white — in rural Trinity, N.C., she envisioned boosting their community impact by having them do ministry projects together.',
		},
		{
			paragraph:
				'But that proved difficult, given a history of “hurts and wounds” between the Black and white parishioners. To build and repair relationships, “people need to heal. People need to be whole,” she said. “They need to be able to express themselves in a ‘brave space.’”',
		},
		{
			paragraph:
				'She created that space and rebuilt the congregations’ relationship with guidance from Disciples of Welcome, a two-year “learning journey” for United Methodist pastors run by Faith Matters Network, a Tennessee-based nonprofit. The initiative seeks to equip United Methodist pastors as agents of hope across polarizing issues of race, sexuality or political ideology.',
		},
		{
			paragraph:
				'Supported by a $525,000 grant from the Endowment, Disciples of Welcome included a series of retreats, peer group sessions and one-on-one coaching for about 50 pastors from across North Carolina. By framing discussions around the Biblical call to hospitality, the encounters opened those brave spaces of vulnerability among the pastors, allowing them to share raw, honest stories and draw closer, even amid continuing points of disagreement.',
		},
		{
			paragraph:
				'Jennifer Bailey, director of Faith Matters Network, said the objective was to show pastors, through their own exchanges, how storytelling can open space for new conversations across differences. “We believe that relationships move at the speed of trust, and social change moves at the speed of relationships,” she said.',
		},
		{
			paragraph:
				'The Rev. Gloria Winston-Harris, lead facilitator for Disciples of Welcome, described the program as an answered prayer. “Throughout this learning journey,” she said, “I have seen how much Disciples of Welcome impacts United Methodist clergy in North Carolina as well as the broader communities they serve.”',
		},
		{
			paragraph: 'Supporters hope to apply the model in broader groups and social contexts.',
		},
		{
			paragraph:
				'“The Church has the tools to help heal today’s divisions,” said Dr. Dennis Campbell, chair of the Endowment’s Committee on the Rural Church. “Disciples of Welcome helps people see each other as they should, as Imago Dei — being made in the image of God.”',
		},
	],
};

const spotlight4Content = {
	bucketBold: '“People need to heal. People need to be whole.',
	bucket: 'They need to be able to express themselves in a ‘brave space.’”',
	spotlightOverline: 'Rural Church:',
	spotlightTitle: 'HELPING PASTORS HEAL DIVISIONS',
};

const spotlight5Data = {
	spotlightTitle: 'Special initiatives:',
	title: 'Listening to African American Parents',
	message: [
		{
			paragraph:
				'As Sanaa Sharrieff sought therapeutic help for her son with special needs, she felt lost in a maze of disconnected agencies and services.',
		},
		{
			paragraph:
				'Her frustration dissipated after she joined the Guilford Parent Leader Network, a group that brings a parent’s perspective to bear on the work of the Get Ready Guilford Initiative, an ambitious 10-year strategy to reform early childhood systems in Guilford County. At one of her earliest network meetings, Sharrieff met a psychiatrist who could help her son.',
		},
		{
			paragraph:
				'“We had a one-on-one conversation with no middle man,” she said. “I felt heard. I felt respected as a parent. It almost brings me to tears because it took so long to get to that level of service.”',
		},
		{
			paragraph:
				'Advocates say early childhood policies are often created without centering families, leading to programs that fail to achieve the intended impact on the most marginalized families. They believe reform campaigns such as Get Ready Guilford can’t succeed in providing equitable outcomes to Black children unless the adults who know the children best — their parents — are listened to and empowered.',
		},
		{
			paragraph:
				'Get Ready Guilford leaders say they try hard to avoid the old stereotype of white do-gooders swooping in to “fix” African American communities.',
		},
		{
			paragraph:
				'“People want a say in the trajectory of things affecting their lives,” said Heather Adams, director of engagement and literacy initiatives for Get Ready Guilford. “By centering families’ voices in our work and being truly responsive to their needs, that is how we build an equitable system.”',
		},
		{
			paragraph:
				'Get Ready Guilford is led by Ready for School, Ready for Life, a leading early childhood organization in the county. It seeks to improve individual and population-level outcomes among 55,000 area children, prenatal through age 8.',
		},
		{
			paragraph:
				'Get Ready Guilford embedded equity in its Values & Principles statement, committing to be family-led, inclusive, data-focused and equity-driven. Its Parent Leader Network provides input on major decisions. Parent leaders were placed on the Ready for School, Ready for Life Equity Strategies Committee that is crafting the organization’s equity action plan.',
		},
		{
			paragraph:
				'“Demography does not have to equal destiny for children from under-resourced communities,” said Dr. Jean Spaulding, chair of the Endowment’s Get Ready Guilford Ad Hoc Committee. “By engaging families deeply and consistently, we can build early childhood systems that give all children an equitable chance at success.”',
		},
	],
};

const spotlight5Content = {
	title: 'The Road Ahead',
	firstMessage: [
		{
			paragraph:
				'In 2020, the Endowment took an empirical look at how equitably its dollars are distributed by disaggregating grantmaking data by race and socioeconomic status. The analysis showed that from 2017 to June 2020, $141 million went to programs and services benefiting African Americans. That comprised 24 percent of the grant money allocated, meaning the share going to African Americans nearly mirrored their percentage of the Carolinas’ population. ',
		},
		{
			paragraph:
				'However, African Americans account for at least a third of those experiencing poverty in the Carolinas, which suggests our connection to historically marginalized and lower-income communities is more limited than it should be.',
		},
	],
	secondMessage: [
		{
			paragraph:
				' With that in mind, President Rhett Mabry convened more than a dozen foundation leaders from across the Carolinas, seeking to pool resources to identify and support organizations led by people of color and working in communities of color.',
		},
		{
			paragraph:
				'Internally, the Endowment has embarked on a holistic approach that includes training staff; reassessing operations practices around issues such as hiring and vendor selection; and building greater racial diversity on our staff and management team.',
		},
		{
			paragraph:
				'Given the role of racial inequity in our society and its connection to poverty, we cannot ignore its impact and still hope to achieve our founder’s dream. We plan to apply an equity lens systematically to shape our grantmaking and organizational culture in the years ahead. We look forward with optimism, even as we acknowledge the hard work before us.',
		},
	],
	bucketBold: '“By centering families’ voices in our work and being truly responsive to their needs, ',
	bucket: 'that is how we build an equitable system.”',
	spotlightOverline: 'Special initiatives:',
	spotlightTitle: 'Listening to African American Parents',
};

const newsModalData1 = {
	bgColor: '#087482',
	img: newsImage1,
	title: 'COVID RELIEF GRANTS',
	message: [
		{
			paragraph:
				'In our ongoing response to the COVID-19 pandemic, Trustees have awarded more than $28 million to address critical community needs across the Carolinas.',
		},
		{
			paragraph:
				'In March 2020, the Board approved $2.5 million in relief funding with an emphasis on supporting statewide efforts, particularly around health care and social supports for families and communities. Initial grants of $1.25 million each went to the North Carolina Healthcare Foundation and to the One SC Fund to help provide access to health care and meet basic needs.',
		},
		{
			paragraph:
				'The Endowment also awarded a $3.5 million grant to Feeding the Carolinas, a network of 10 food banks serving more than 3,700 charitable agencies in both states. The funding helped the organizations meet increased demand as a result of the pandemic.',
		},
		{
			paragraph:
				'In December, as COVID-19 continued to impact communities, the Trustees approved $3.8 million to bolster services and resources for demographic groups disproportionately affected by the crisis. The funding was designated for eight organizations, with a focus on helping historically marginalized communities suffering disparate rates of illness and death, job losses and other hardships. Assisting with those grants was a panel of 13 staff members whose research helped direct more resources toward African American, Native American/Indigenous and Latino families in need.',
		},
		{
			paragraph:
				'Our grantmaking has continued with support for health care providers, charitable groups and others working to relieve hunger and boost vaccination rates within communities of color.',
		},
	],
};

const newsModalData2 = {
	bgColor: '#85479C',
	img: newsImage2,
	title: 'New Fellowship Program Launches',
	message: [
		{
			paragraph:
				'After redesigning our Fellowship program, we were excited to welcome Michele Manceaux as our 2020-21 Fellow. Michele, a Davidson College graduate from Euless, Texas, spent her year working with the Rural Church program area’s Summer Literacy Initiative, which is helping early readers in rural communities avoid learning loss over the school break. ',
		},
		{
			paragraph:
				'The Fellowship is designed to provide hands-on experience in the philanthropic sector. Our redesigned program focuses on talented candidates from the four campuses supported by the Endowment: Davidson College, Duke University, Furman University and Johnson C. Smith University. It also shifts the Fellowship’s length from two years to one, and Fellows are hired to work on one significant project, rather than rotating through the program areas.',
		},
		{
			paragraph:
				'The Endowment is committed to this dynamic program and excited about giving it new life. Most of all, we’re eager to welcome future Fellows to our work. ',
		},
	],
};
const newsModalData3 = {
	bgColor: '#ED5B26',
	img: newsImage3,
	title: 'Get Ready Guilford Initiative Update',
	message: [
		{
			paragraph:
				'Despite disruptions from the pandemic, leaders of the Get Ready Guilford Initiative forged ahead with plans to build an integrated and responsive early childhood system in Guilford County. The initiative, a joint endeavor by the Endowment and Ready for School, Ready for Life, a major early childhood organization in the county, aims to improve population-level outcomes for children prenatal to age 8 across a range of key indicators. ',
		},
		{
			paragraph:
				'COVID-19 caused participating programs to shift their models from in-person to virtual formats. Reduced client access and staff availability resulted in lower-than-expected participation rates. Still, the county’s early childhood leaders continued to invest time and resources to adapt, plan and evaluate critical elements, such as streamlining service navigation for families. ',
		},
		{
			paragraph:
				'Ready for School, Ready for Life continued to grow its team, hiring Charrise Hart as its new CEO in 2019 and filling additional leadership roles in 2020. The programs anchoring Phase 1 of the four-part effort — Family Connects, HealthySteps and Nurse-Family Partnership — pursued their expansion goals while finalizing changes to their models for a seamless family experience. (Phase 1 targets children prenatal to age 3.) Organizers prepared for Phase 2, which will add 3- and 4-year-olds to the target population.',
		},
	],
};
const newsModalData4 = {
	bgColor: '#4859A7',
	img: newsImage4,
	title: 'Upstream USA',
	message: [
		{
			paragraph:
				'The Board of Trustees approved a five-year, $10 million grant to support Upstream USA’s effort to increase access to contraceptive care and reduce unintended pregnancies in North Carolina. Upstream, founded in 2014, advances clinic-based interventions to improve reproductive health care access. It has launched statewide initiatives in North Carolina and other states in hopes of reducing unplanned pregnancies and improving long-term outcomes for families. The Endowment’s support will assist Upstream’s expansion effort in North Carolina, where an estimated 43 percent of pregnancies are unplanned, producing nearly 38,000 unplanned births annually.',
		},
		{
			paragraph:
				'Upstream plans to deliver customized training and technical assistance to partnering hospital systems, public health departments, federally qualified health centers, clinics and private providers. Health centers will partner with Upstream for 12 to 18 months to integrate Long-Acting Reversible Contraceptives, or LARCS, among the array of contraception offered. Upstream anticipates reaching as many as 400 sites and 400,000 women annually. ',
		},
	],
};
const newsModalData5 = {
	bgColor: '#E8A622',
	img: newsImage5,
	title: 'Refining Our Strategy',
	message: [
		{
			paragraph:
				'For the past several years, our program area teams have been systematically reviewing their grantmaking strategies, seeking input from grantees and others, and making necessary revisions. The goal is to have greater clarity — for staff and for grantees — about what the Endowment is trying to accomplish (our aims), why we may take different philanthropic approaches in different program areas, what strategies we are using to achieve our aims, and how we will know if we are successful. ',
		},
		{
			paragraph:
				'Staff and Trustees in late 2018 discussed each program area’s overall aim and philanthropic approach. A year later, staff presented overviews of the program areas’ theories of change, laying out the outcomes they sought to achieve and the strategies for reaching those results. In 2020, they presented more detailed theories of change and crafted evaluation and learning plans to measure the impact of their grantmaking and track progress against their strategies. ',
		},
		{
			paragraph:
				'We plan to wrap up our strategy refresh in 2021, reviewing and adjusting as the work progresses. ',
		},
	],
};

const staffData1 = [
	{
		name: 'Ashleigh J. Allessio',
		title: 'Grants Analyst',
	},
	{
		name: 'William F. Bacon',
		title: 'Vice President/Director, Evaluation',
	},
	{
		name: 'Laila A. Bell',
		title: 'Associate Director, Learning & Evaluation',
	},
	{
		name: 'Chris M. Collins',
		title: 'Associate Director, Health Care',
	},
	{
		name: 'Todd W. Dalrymple',
		title: 'Associate Director, Special Initiatives',
	},
	{
		name: 'Justin M. Dunham',
		title: 'Program Analyst, Health Care',
	},
	{
		name: 'Eric E. Frazier',
		title: 'Digital Communications Strategist',
	},
	{
		name: 'Kate A. Gaskin',
		title: 'Senior Administrative Specialist, Child & Family Well-Being',
	},
	{
		name: 'Paula W. Greene',
		title: 'Events Manager',
	},
	{
		name: 'Julie A. Hale',
		title: 'Senior Administrative Specialist, Health Care',
	},
	{
		name: 'Melinda O. Hardin',
		title: 'Accounting Specialist',
	},
	{
		name: 'Charisma J. Hibbler',
		title: 'Senior Administrative Specialist, Evaluation',
	},
	{
		name: 'Lin B. Hollowell III',
		title: 'Director, Health Care',
	},
	{
		name: 'Stella J. Jalon',
		title: 'Executive Assistant, President’s Office',
	},
	{
		name: 'Anmar Y. Jerjees',
		title: 'Program Analyst, Rural Church',
	},
	{
		name: 'Jay E. Kennedy',
		title: 'Program Officer, Health Care',
	},
];
const staffData2 = [
	{
		name: 'Jeri F. Krentz ',
		title: 'Associate Director, Communications',
	},
	{
		name: 'Elizabeth A. Kupec',
		title: 'Senior Administrative Specialist, Information Technology',
	},
	{
		name: 'Allen P. Lane',
		title: 'Office Assistant',
	},
	{
		name: 'Rhett N. Mabry',
		title: 'President',
	},
	{
		name: 'Tania G. Mapes',
		title: 'Human Resources Manager',
	},
	{
		name: 'Michele S. Manceaux',
		title: 'Fellow, Rural Church',
	},
	{
		name: 'Ali Marzouq',
		title: 'Network Engineer, Information Technology',
	},
	{
		name: 'Trena McClure',
		title: 'Senior Administrative Specialist, Rural Church',
	},
	{
		name: 'Susan L. McConnell ',
		title: 'Vice President/Director, Higher Education <br /> Director, Human Resources',
	},
	{
		name: 'Laura A. Peres',
		title: 'Project and Facilities Manager',
	},
	{
		name: 'Charity L. Perkins',
		title: 'Director, Communications',
	},
	{
		name: 'Phillip H. Redmond Jr.',
		title: 'Director, Child & Family Well-Being',
	},
	{
		name: 'Kristen R. Richardson-Frick',
		title: 'Associate Director, Rural Church',
	},
	{
		name: 'Karen H. Rogers',
		title: 'Chief Financial Officer/Treasurer',
	},
	{
		name: 'Meka S. Sales',
		title: 'Director, Special Initiatives',
	},
];
const staffData3 = [
	{
		name: 'Matthew D. Sharp',
		title: 'Director, Information Technology',
	},
	{
		name: 'Natalie C.W. Smith',
		title: 'Controller',
	},
	{
		name: 'K. Todd Walker',
		title: 'Managing Director, Investments',
	},
	{
		name: 'Kristi K. Walters',
		title: 'Program Officer, Higher Education',
	},
	{
		name: 'Stacy E. Warren',
		title: 'Program Officer, Health Care',
	},
	{
		name: 'Robert R. Webb III',
		title: 'Director, Rural Church',
	},
	{
		name: 'Anita W. West',
		title: 'Accounting Manager',
	},
	{
		name: 'Tamika D. Williams',
		title: 'Associate Director, Child & Family Well-Being',
	},
	{
		name: 'Brittany S. Worden',
		title: 'Program Analyst, Special Initiatives',
	},
	{
		name: 'Anita L. Wyatt',
		title: 'Senior Administrative Specialist, Higher Education',
	},
	{
		name: 'Lily H. Zhang',
		title: 'Associate Director, Data & Analytics',
	},
	{
		name: 'Diana Zilberdrut',
		title: 'Project Specialist, Communications/Investments',
	},
];

const trusteesData = [
	{
		img: minor,
		name: 'Minor M. Shaw',
		position: 'Chair',
		title: 'Greenville, SC',
	},
	{
		img: dennis,
		name: 'Dennis M. Campbell',
		position: 'Vice Chair',
		title: ' Durham, NC',
	},
	{
		img: jean,
		name: 'Jean G. Spaulding',
		position: 'Vice Chair',
		title: 'Durham, NC',
	},
	{
		img: empty,
		name: ' ',
		position: ' ',
		title: ' ',
	},
	{
		img: william,
		name: 'William Barnet III',
		position: ' ',
		title: 'Spartanburg, SC',
	},
	{
		img: john,
		name: 'John F.A.V. Cecil',
		position: ' ',
		title: 'Asheville, NC',
	},
	{
		img: ravenel,
		name: 'Ravenel B. Curry III',
		position: ' ',
		title: 'New York, NY',
	},
	{
		img: pamela,
		name: 'Pamela L. Davies',
		position: ' ',
		title: 'Charlotte, NC',
	},
	{
		img: harris,
		name: 'Harris E. DeLoach Jr.',
		position: ' ',
		title: 'Hartsville, SC',
	},
	{
		img: constance,
		name: 'Constance F. Gray',
		position: ' ',
		title: 'Winston-Salem, NC',
	},
	{
		img: trent,
		name: 'J. Trent Jones',
		position: ' ',
		title: 'Sun Valley, ID',
	},
	{
		img: thomas,
		name: 'Thomas S. Kenan III',
		position: ' ',
		title: 'Chapel Hill, NC',
	},
	{
		img: charles,
		name: 'Charles C. Lucas III',
		position: ' ',
		title: 'Charlotte, NC',
	},
	{
		img: clarence,
		name: 'Clarence G. Newsome',
		position: ' ',
		title: 'Mint Hill, NC',
	},
	{
		img: kenneth,
		name: 'Kenneth D. Weeks Jr.',
		position: ' ',
		title: 'Charlotte, NC',
	},
	{
		img: judy,
		name: 'Judy Woodruff',
		position: ' ',
		title: 'Washington, DC',
	},
];

const downloadsData = [
	{
		title: '2020 Annual Report',
		url: annualReport,
		img: annualCover,
	},
	{
		title: '2020 Financial Addendum',
		url: financialAddendum,
		img: financialCover,
	},
	{
		title: '2020 Grantmaking Addendum',
		url: grantmakingAddendum,
		img: grantMakingCover,
	},
];

const menuSectionsKeys = [
	'hero',
	'letter-from-our-leaders',
	'roots-for-growth',
	'child-and-family-well-being',
	'health-care',
	'higher-education',
	'rural-church',
	'special-initiatives',
	'meet-our-leaders',
	'news',
	'downloads',
	'footer',
];

const googleAnalyticsUrl = 'https://www.googletagmanager.com/gtag/js?id=UA-146565798-4';
const googleTagManager =
	"(function (w, d, s, l, i) {w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(),event: 'gtm.js',});var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-5RL2QWJ');";

export {
	headerData,
	narrativeData,
	spotlight2Data,
	spotlight3Data,
	spotlight4Data,
	spotlight5Data,
	newsModalData1,
	newsModalData2,
	newsModalData3,
	newsModalData4,
	newsModalData5,
	staffData1,
	staffData2,
	staffData3,
	heroData,
	lfolData,
	narrativeContent,
	spotlight2Content,
	spotlight3Content,
	spotlight4Content,
	spotlight5Content,
	trusteesData,
	menuSectionsKeys,
	downloadsData,
	googleAnalyticsUrl,
	googleTagManager,
};
